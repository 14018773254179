import { useApolloClient } from "@apollo/client";
import { Auth } from "@aws-amplify/auth";
import { auth as authConfig } from "config/shared";
import useSecurityPrincipalUserMigrate from "hooks/api/useSecurityPrincipalUserMigrate";
import { useUsernameValidateLazy } from "hooks/api/useUsernameValidate";
import noop from "lodash/noop";
import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import { deleteClientConsumerTripCookie } from "utils/consumerTrip/consumerTripCookie";

import { useRouter } from "@holibob-packages/ui-core/navigation";

export type Stage =
    | "INITIALISING"
    | "COGNITO_SIGN_IN"
    | "COGNITO_COGNITO_FORGOT_PASSWORD"
    | "VALIDATE_USERNAME"
    | "COGNITO_FORGOT_PASSWORD_SUBMIT"
    | "COGNITO_COMPLETE_NEW_PASSWORD";

interface AuthState {
    username: string | null;
    password: string | null;
    securityPrincipalUserId: string;
    authenticationMethod: string | null;
    hasAttemptedAuthentication: boolean;
    state: "NONE";
    stage: Stage;
    error: Error | false | null;
    loading: boolean;
    viewer: unknown;
}
const INITIAL_STATE: AuthState = {
    username: null,
    password: null,
    // TSFIXME - can we have a valid initial value here?
    securityPrincipalUserId: null!,
    authenticationMethod: null,
    hasAttemptedAuthentication: false,
    state: "NONE",
    stage: "INITIALISING",
    error: null,
    loading: false,
    viewer: {},
};
export const AuthContext = createContext<ReturnType<typeof useAuthContextValue>>([INITIAL_STATE, noop]);

type Action =
    | {
          type: "COGNITO_AUTHENTICATE";
      }
    | {
          type: "COGNITO_SIGN_IN";
          payload: { securityPrincipalUserId: string; password: string };
      }
    | {
          type: "COGNITO_SIGN_IN_ERROR";
          payload: { error: Error };
      }
    | {
          type: "SIGN_OUT";
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD";
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD_SUCCESS";
          payload: { username: string };
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD_ERROR";
          payload: { error: Error };
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD_SUBMIT";
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD_SUBMIT_SUCCESS";
          payload: { username: string; password: string };
      }
    | {
          type: "COGNITO_FORGOT_PASSWORD_SUBMIT_ERROR";
          payload: { error: Error };
      }
    | {
          type: "COGNITO_COMPLETE_NEW_PASSWORD";
      }
    | {
          type: "COGNITO_COMPLETE_NEW_PASSWORD_SUCCESS";
          payload: { username: string; password: string };
      }
    | {
          type: "COGNITO_COMPLETE_NEW_PASSWORD_ERROR";
          payload: { error: Error };
      }
    | {
          type: "SET_STAGE";
          payload: Pick<AuthState, "stage"> & Partial<AuthState>;
      };
function authReducer(state: AuthState, action: Action): AuthState {
    const { type } = action;

    switch (type) {
        case "COGNITO_AUTHENTICATE": {
            return {
                ...state,
                hasAttemptedAuthentication: true,
            };
        }
        case "COGNITO_SIGN_IN": {
            const { securityPrincipalUserId, password } = action.payload;
            return {
                ...state,
                securityPrincipalUserId,
                password,
                loading: true,
            };
        }
        case "COGNITO_SIGN_IN_ERROR": {
            const { error } = action.payload;

            return {
                ...state,
                password: null,
                loading: false,
                error,
            };
        }
        case "SIGN_OUT": {
            return {
                ...state,
                stage: "VALIDATE_USERNAME",
                username: null,
                password: null,
                loading: false,
                error: false,
            };
        }
        case "COGNITO_FORGOT_PASSWORD": {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }

        case "COGNITO_FORGOT_PASSWORD_SUCCESS": {
            const { username } = action.payload;
            return {
                ...state,
                username,
                loading: false,
                stage: "COGNITO_FORGOT_PASSWORD_SUBMIT",
            };
        }
        case "COGNITO_FORGOT_PASSWORD_ERROR": {
            const { error } = action.payload;

            return {
                ...state,
                username: null,
                loading: false,
                error,
            };
        }
        case "COGNITO_FORGOT_PASSWORD_SUBMIT": {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }

        case "COGNITO_FORGOT_PASSWORD_SUBMIT_SUCCESS": {
            const { username, password } = action.payload;
            return {
                ...state,
                username,
                password,
                loading: false,
            };
        }
        case "COGNITO_FORGOT_PASSWORD_SUBMIT_ERROR": {
            const { error } = action.payload;

            return {
                ...state,
                username: null,
                loading: false,
                error,
            };
        }

        case "COGNITO_COMPLETE_NEW_PASSWORD": {
            return {
                ...state,
                loading: true,
                error: false,
            };
        }

        case "COGNITO_COMPLETE_NEW_PASSWORD_SUCCESS": {
            const { username, password } = action.payload;
            return {
                ...state,
                username,
                password,
                loading: false,
            };
        }
        case "COGNITO_COMPLETE_NEW_PASSWORD_ERROR": {
            const { error } = action.payload;

            return {
                ...state,
                username: null,
                loading: false,
                error,
            };
        }
        case "SET_STAGE": {
            const { stage, ...rest } = action.payload;

            return {
                ...state,
                stage,
                ...rest,
            };
        }

        default:
            return state;
    }
}

export function useAuthContextValue() {
    useEffect(() => {
        if (typeof window !== "undefined") {
            Auth.configure(authConfig);
        }
    }, []);
    return useReducer(authReducer, INITIAL_STATE);
}

export function useAuthContext() {
    return useContext(AuthContext);
}

export function useAuthState() {
    const [state] = useAuthContext();

    return state;
}

function useAuthDispatch() {
    const [, dispatch] = useAuthContext();
    return dispatch;
}

export function useUsernameValidate() {
    const [usernameValidate] = useUsernameValidateLazy();
    const dispatch = useAuthDispatch();

    return useCallback(
        async (username: string) => {
            const response = await usernameValidate(username);

            const { authenticationMethod } = response;
            if (
                authenticationMethod === "COGNITO" ||
                authenticationMethod === "COGNITO_LEGACY" ||
                authenticationMethod === "HOLIBOB_IDP"
            ) {
                dispatch({
                    type: "SET_STAGE",
                    payload: { stage: "COGNITO_SIGN_IN", username, ...response },
                });
            }

            return { username, ...response };
        },
        [usernameValidate, dispatch]
    );
}

export function useSignIn() {
    const cognitoSignIn = useCognitoSignIn();

    return useCallback(
        async (params: Parameters<typeof cognitoSignIn>[0]) => {
            await cognitoSignIn(params);
        },
        [cognitoSignIn]
    );
}

export async function cognitoIdentityProviderSignIn() {
    await Auth.federatedSignIn({ customProvider: "AzureAD" });
}

export function useCognitoSignIn() {
    const dispatch = useAuthDispatch();
    const [securityPrincipalUserMigrate] = useSecurityPrincipalUserMigrate();
    const { authenticationMethod } = useAuthState();
    const cognitoSignOut = useCognitoSignOut();

    return useCallback(
        async ({
            securityPrincipalUserId,
            password,
            returnTo,
        }: {
            securityPrincipalUserId: string;
            password: string;
            returnTo: string;
        }) => {
            if (authenticationMethod === "COGNITO_LEGACY") {
                await securityPrincipalUserMigrate({ password, securityPrincipalUserId });
            }

            dispatch({ type: "COGNITO_SIGN_IN", payload: { securityPrincipalUserId, password } });

            try {
                const { challengeName } = await Auth.signIn(securityPrincipalUserId, password);

                if (challengeName === "NEW_PASSWORD_REQUIRED") {
                    dispatch({
                        type: "SET_STAGE",
                        payload: { stage: "COGNITO_COMPLETE_NEW_PASSWORD", securityPrincipalUserId, password },
                    });
                } else {
                    try {
                        window.location.href = returnTo;
                    } catch (error) {
                        const { networkError } = error;
                        const errors = networkError?.result?.errors || [];
                        error.message = errors[0]?.message || error.message;
                        throw error;
                    }
                }
                deleteClientConsumerTripCookie();
            } catch (error) {
                await cognitoSignOut();
                dispatch({ type: "COGNITO_SIGN_IN_ERROR", payload: { error: error.message } });
                throw error;
            }
        },
        [authenticationMethod, cognitoSignOut, dispatch, securityPrincipalUserMigrate]
    );
}

export function useCognitoSignOut() {
    return useCallback(async () => {
        await Auth.signOut();
        deleteClientConsumerTripCookie();
    }, []);
}

export function useCognitoCompleteNewPassword() {
    const cognitoSignIn = useCognitoSignIn();

    return useCallback(
        async ({
            securityPrincipalUserId,
            currentPassword,
            newPassword: password,
            returnTo,
        }: {
            securityPrincipalUserId: string;
            currentPassword: string;
            newPassword: string;
            returnTo: string;
        }) => {
            const user = await Auth.signIn(securityPrincipalUserId, currentPassword);
            await Auth.completeNewPassword(user, password);

            await cognitoSignIn({ securityPrincipalUserId, password, returnTo });
        },
        [cognitoSignIn]
    );
}

export function useSetFlowStage() {
    const dispatch = useAuthDispatch();

    return useCallback(
        (stage: Stage) => {
            dispatch({ type: "SET_STAGE", payload: { stage } });
        },
        [dispatch]
    );
}

export function useSignOut() {
    const cognitoSignOut = useCognitoSignOut();
    const dispatch = useAuthDispatch();
    const client = useApolloClient();
    const router = useRouter();

    return useCallback(async () => {
        dispatch({ type: "SIGN_OUT" });
        router.push("/");
        await cognitoSignOut();
        await client.resetStore();
    }, [dispatch, router, cognitoSignOut, client]);
}

export function useAuthStage() {
    const { stage } = useAuthState();

    return stage;
}

export function useHasAttemptedAuthentication() {
    const { hasAttemptedAuthentication } = useAuthState();

    return hasAttemptedAuthentication;
}

export function useAuthStateUsername() {
    const { username } = useAuthState();

    return username;
}

export function useAuthStateSecurityPrincipalUserId() {
    const { securityPrincipalUserId } = useAuthState();

    return securityPrincipalUserId;
}
